import { reactive, ref } from 'vue'

import { loading$ } from '@/services'

import { isOnVitalCampaignPageWhileLogin } from '@/utils/HaX'
import { ReactivityUtil } from '@/utils/Reactivity'

import basketAPI from '@/api/basket'
import userRegistryAPI from '@/api/userRegistry'
import useI18n from '@/hooks/useI18n'

import { HN_VERIFICATION_STATE, LOGIN_ERROR_STATE, LOGIN_STEP } from '@/config/constants'

// HOOKS
const { t } = useI18n()

// DATA
const answers = reactive([])
const partnerNumber = ref('')
const question = reactive({})
const hasCardError = ref(false)

// METHODS
function mapAnswers(rawAnswers) {
  return rawAnswers.map(a => {
    return {
      text: t(`login.authform.hn.answers.${a.answerKey}`),
      value: a.answerKey,
    }
  })
}

export default function useHn() {
  async function verifyPartnerNumber(partnerNr) {
    loading$.start({ blocking: false })
    let nextStep
    try {
      const response = await userRegistryAPI.startVerificationProcess({ partnerNumber: partnerNr })
      const responseState = response.state
      partnerNumber.value = partnerNr
      switch (responseState) {
        case HN_VERIFICATION_STATE.VERIFY_CARD:
          nextStep = LOGIN_STEP.AUTH_HN_CARD_NUMBER
          loading$.end()
          break
        case HN_VERIFICATION_STATE.VERIFY_QUESTION:
          ReactivityUtil.reAssign(question, response.question)
          ReactivityUtil.reAssign(answers, mapAnswers(response.answers))
          loading$.end()
          nextStep = LOGIN_STEP.AUTH_HN_QUESTIONS
          break
        case HN_VERIFICATION_STATE.LOCKED_TEMPORARILY:
          loading$.end()
          throw new Error(LOGIN_ERROR_STATE.LOCKED)
        case HN_VERIFICATION_STATE.VERIFIED:
          loading$.end()
          throw new Error(LOGIN_ERROR_STATE.LOGGED_OUT)
      }

      return nextStep
    } catch {
      loading$.failed()
      throw new Error(LOGIN_ERROR_STATE.GENERAL)
    }
  }
  async function verifyCardNumber(cardNumber) {
    loading$.start({ blocking: false })
    try {
      const response = await userRegistryAPI.verifyCard({
        answerText: cardNumber,
        partnerNumber: partnerNumber.value,
      })
      if (response.state === HN_VERIFICATION_STATE.LOCKED_TEMPORARILY) {
        loading$.end()
        throw new Error(LOGIN_ERROR_STATE.LOCKED)
      }
      ReactivityUtil.reAssign(question, response.question)
      ReactivityUtil.reAssign(answers, mapAnswers(response.answers))
      loading$.end()
      return LOGIN_STEP.AUTH_HN_QUESTIONS
    } catch (e) {
      loading$.failed()
      if (e.data?.state === HN_VERIFICATION_STATE.VERIFY_CARD) {
        hasCardError.value = true
        return
      }
      throw new Error(LOGIN_ERROR_STATE.GENERAL)
    }
  }

  async function verifyAnswer(answerKey) {
    loading$.start({ blocking: false })
    let next = { step: '', data: {} }
    try {
      const response = await userRegistryAPI.verifyAnswer({
        answerKey,
        partnerNumber: partnerNumber.value,
        questionKey: question.questionKey,
        questionPosition: question.position,
      })
      const responseState = response.state
      switch (responseState) {
        case HN_VERIFICATION_STATE.VERIFY_QUESTION:
          ReactivityUtil.reAssign(question, response.question)
          ReactivityUtil.reAssign(answers, mapAnswers(response.answers))
          next.step = LOGIN_STEP.AUTH_HN_QUESTIONS
          break
        case HN_VERIFICATION_STATE.VERIFIED: {
          const __basket = await basketAPI.getBasketByPartnerNumber({
            partnerNumber: partnerNumber.value,
            uid: response.uid,
          })
          next = {
            step: LOGIN_STEP.SUCCESS,
            data: { basket: __basket, partnerNumber: partnerNumber.value, uid: response.uid },
          }
          break
        }
        case HN_VERIFICATION_STATE.LOCKED_TEMPORARILY:
          loading$.failed()
          throw new Error(LOGIN_ERROR_STATE.LOCKED)
      }

      loading$.end()
      return next
    } catch (/** @type {ErrorResponse}*/ error) {
      loading$.failed()
      // first, check, if we're on the correct campaign-page
      if (isOnVitalCampaignPageWhileLogin()) {
        throw new Error(LOGIN_ERROR_STATE.DVP_UNSUPPORTED)
      } else {
        throw new Error(LOGIN_ERROR_STATE.GENERAL)
      }
    }
  }

  return {
    answers,
    hasCardError,
    question,
    verifyAnswer,
    verifyCardNumber,
    verifyPartnerNumber,
  }
}
