<template>
  <div class="prompt">
    <div
      class="body-2-expanded prompt__text"
      v-text="$t('login.prompt.text')"
    />

    <login-modal
      ref="actionModalRef"
      :modal-id="$props.modalId"
    />

    <basic-link
      class="prompt__link"
      data-track-as="login_start"
      :data-track-text="$props.channel"
      :label="$t('login.prompt.title')"
      @click="openLoginModal"
    >
      <template #trailing>
        <the-icon
          art="solid"
          name="arrow-right"
        />
      </template>
    </basic-link>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import events$ from '@/services/Events'

import BasicLink from '@/components/Basic/Link'

import LoginModal from '@/components/Login/Modal'

import { EVENT_LOGIN } from '@/config/events'

// INIT
const props = defineProps({
  callerId: {
    type: String,
    default: null,
  },

  channel: {
    type: String,
    required: true,
  },

  hideNotification: {
    type: Boolean,
    default: false,
  },

  modalId: {
    type: String,
    required: true,
  },

  navigateToDvp: {
    type: Boolean,
    default: false,
  },
})

// DATA
const actionModalRef = ref(null)

// METHODS
function openLoginModal() {
  const payload = {
    callerId: props.callerId,
    channel: props.channel,
    hideNotification: props.hideNotification,
    modalId: props.modalId,
    navigateToDvp: props.navigateToDvp,
  }

  events$.emit(EVENT_LOGIN.OPEN, payload)
}

defineExpose({ actionModalRef })
</script>

<style scoped>
.prompt {
  display: flex;
  flex-direction: column;
}

@media (--v-medium) {
  .prompt {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: var(--fixed-spacing-fix-04);
    align-items: center;
  }
}
</style>
