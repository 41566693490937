import { isRequired, MissingParameterError } from '@/utils/CustomErrors'

import config from '@/config'

import useBackend from '@/hooks/useBackend'

function userRegistry() {
  // HOOKS
  const { buildURL, doPost, setup } = useBackend()

  // INIT
  setup('/userregistry/public/v1', config.microserviceBaseUrl)

  // METHODS
  async function checkState({ partnerNumber = isRequired('partnerNumber') }) {
    const response = await doPost('/identity-verification/state', { partnerNumber })
    return response.data
  }

  /**
   * startVerificationProcess sends a POST-requrest to /identity-verification/process
   * with the partnerNumber to start the verification process
   * @param {object} payload
   * @param {string} payload.partnerNumber
   * @returns {Promise<object>}
   */
  async function startVerificationProcess(payload) {
    if (!payload.partnerNumber) throw new MissingParameterError('partnerNumber')

    const action = 'ONLINE'
    const forceReset = true
    const url = buildURL('/identity-verification/process', { action, forceReset })
    const response = await doPost(url, payload)
    return response.data
  }

  /**
   * verifyAnswer sends a POST-request to /identity-verification/question
   * with the question/answer pair to verify the user
   * @param {object} payload
   * @param {string} payload.answerKey
   * @param {string} payload.partnerNumber
   * @param {string} payload.questionKey
   * @param {string} payload.questionPosition
   * @returns {Promise<object>}
   *
   */
  async function verifyAnswer(payload) {
    if (!payload.answerKey) throw new MissingParameterError('answerKey')
    if (!payload.partnerNumber) throw new MissingParameterError('partnerNumber')
    if (!payload.questionKey) throw new MissingParameterError('questionKey')
    if (!payload.questionPosition) throw new MissingParameterError('questionPosition')

    const response = await doPost('/identity-verification/question', payload)
    return response.data
  }

  /**
   * verifyCard sends a POST-request to /identity-verification/card
   * to check if the last 4 digits sent match the card of the partnerNumber
   * @param {object} payload
   * @param {string} payload.answerText
   * @param {string} payload.partnerNumber
   * @returns {Promise<object>}
   */
  async function verifyCard(payload) {
    if (!payload.answerText) throw new MissingParameterError('answerText')
    if (!payload.partnerNumber) throw new MissingParameterError('partnerNumber')

    const response = await doPost('/identity-verification/card', payload)
    return response.data
  }

  return {
    checkState,
    startVerificationProcess,
    verifyAnswer,
    verifyCard,
  }
}

export default userRegistry()
